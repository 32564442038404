<template>
    <div class="container">
      <div class="register">
        <div class="register-box">
            <div class="top">
                <span class="title">注册账号</span>
            </div>
            <!-- 中间输入框 -->
            <div class="input-box">
                <div class="input-phone-text-box">
                    <input type="text" class="input-phone-text" v-model="formData.phoneNumber" @input="inputPhoneNumberChange()" placeholder="请输入手机号">
                    <p v-if="startPhoneNumberValidate" class="input-phone-tips-text">手机号格式错误</p>
                </div>
                <!-- 发送短信 -->
                <div class="sms-code-box">
                    <input type="number" class="sms-code" v-model="formData.code" @input="inputCodeChange()" placeholder="请输入短信验证码">
                    <div class="sms-code-btn" @click="startCountdown()" :style="{cursor:isDisabled?'not-allowed':'pointer',background:isDisabled?'#ccc':''}">
                        <span>{{buttonText}}</span>
                    </div>
                    <p v-if="startSMSCodeValidate" class="sms-code-tips-text">验证码格式错误</p>
                </div>
                <div class="password-box">
                    <input type="password" class="input-text-pw" v-model="formData.password" ref="password" @focus="startPasswordValidate=false" placeholder="请输入密码">
                    <div class="show-or-hidden-password-btn" @click="clickShowOrHiddenBtnHandler()">
                        <img class="show-or-hidden-password-icon" :src="showPassword?'/images/login/login_hidden_password_icon.png':'/images/login/login_show_password_icon.png'" alt="隐藏密码">
                    </div>
                    <p v-if="startPasswordValidate" class="password-tips-text">两次输入的密码不一致</p>
                </div>
                <div class="password-box" >
                    <input type="password" class="input-text-pw" ref="toggle-password" @focus="startPasswordValidate=false" v-model="confirmPassword" @input="inputPasswordChange()" placeholder="请再次确认密码">
                    <div class="show-or-hidden-password-btn" @click="clickShowOrHiddenBtnHandler()">
                        <img class="show-or-hidden-password-icon" :src="showPassword?'/images/login/login_hidden_password_icon.png':'/images/login/login_show_password_icon.png'" alt="隐藏密码">
                    </div>
                    <p v-if="startPasswordValidate"  class="password-tips-text">两次输入的密码不一致</p>
                </div>
            </div>
            <!-- 注册按钮 -->
            <div class="register-btn" @click="clickRegisterBtnHandler()">
                <span>注册</span>
            </div>
            <div class="go-to-login">
                <span class="text-1">已有账号？</span>
                <span class="text-2"  @click="$router.push('/login')">立即登录</span>
            </div>
            <!-- 用户协议 -->
            <div class="user-agreement">
                <img class="icon-select"  @click="clickUerAgreementBtnHandler()" :src="userAgreement?'/images/login/login_user_has_agreement_icon.png':'/images/login/login_user_not_agreement_icon.png'" alt="用户协议">
                <span class="text-1">我已阅读并同意</span>
                <span class="text-2">《用户协议》</span>
                <span class="text-1">和</span>
                <span class="text-2">《隐私政策》</span>
            </div>

        </div>
      </div>
    </div>
  </template>
  
  <script>
  import {register,sendVerifyCode} from '../../../services/user/index.js';
  export default {
    name:"RegisterContent",
    data(){
        return{
            showPassword:true, //是否显示密码
            userAgreement:false, //用户协议是否大勾
            confirmPassword:'', //确认密码
            buttonText:'获取短信验证码',// 按钮显示的文字
            timer: null,        // 定时器
            countdown: 60,      // 倒计时时间
            isDisabled: false,  // 按钮是否禁用
            formData:{
                phoneNumber:'',
                password:'',
                code:''
            },
            phoneNumberTimer:null,
            startPhoneNumberValidate:false,//手机号校验提示
            SMSCodeTimer:null,
            startSMSCodeValidate:false,//短信验证码校验提示
            passwordTimer:null,
            startPasswordValidate:false,//密码校验提示
        }
    },
    methods:{
        async startCountdown() {
            // 如果倒计时已经开始（isDisabled 为 true），则不再触发倒计时
            if (this.isDisabled) {
                return;
            }
            try{
                if(!this.userAgreement){
                    this.$message.warning("请先同意《用户协议》与《隐私政策》")
                    return;
                }
                if(!(/^1[3456789]\d{9}$/.test(this.formData.phoneNumber))){
                    this.$message.warning("请填写正确的手机号码")
                    return;
                }
                //codeType：1密码登录 2短信登录 3注册 4找回密码
                const {data} = await sendVerifyCode(3,this.formData.phoneNumber);
                if(data.code!==200){
                    this.$message.warning("发送短信失败"+data.message);
                    return;
                }
            }catch(err){}
            // 启动倒计时
            this.isDisabled = true;
            this.buttonText = `${this.countdown}秒后重试`;
            this.timer = setInterval(() => {
                this.countdown--;
                if (this.countdown > 0) {
                this.buttonText = `${this.countdown}秒后重试`;
                } else {
                this.resetButton();
                }
            }, 1000);
            
        },
        resetButton() {
            // 重置按钮状态
            clearInterval(this.timer);
            this.countdown = 60;
            this.buttonText = "获取验证码";
            this.isDisabled = false;
        },
        //点击是否显示密码按钮
        clickShowOrHiddenBtnHandler(){
            this.showPassword = ! this.showPassword;
            if(this.showPassword){
                this.$refs['password'].type = 'password';
                this.$refs['toggle-password'].type = 'password';
            }else{
                this.$refs['password'].type = 'text';
                this.$refs['toggle-password'].type = 'text';
            }
        },
        //点击用户协议按钮
        clickUerAgreementBtnHandler(){
            this.userAgreement = !this.userAgreement;
        },
        //手机号校验
        inputPhoneNumberChange(){
            clearTimeout(this.phoneNumberTimer)
            this.phoneNumberTimer=setTimeout(()=>{
                if(/^1[3456789]\d{9}$/.test(this.formData.phoneNumber)){
                    this.startPhoneNumberValidate = false;
                }else{
                    this.startPhoneNumberValidate = true;
                }
            },600)
        },
        //短信验证码校验
        inputCodeChange(){
            clearTimeout(this.SMSCodeTimer)
            this.SMSCodeTimer=setTimeout(()=>{
                if(/^\d{6}$/.test(this.formData.code)){
                    this.startSMSCodeValidate = false;
                }else{
                    this.startSMSCodeValidate = true;
                }
            },600)
        },
        //密码校验
        inputPasswordChange(){
            clearTimeout(this.passwordTimer)
            this.passwordTimer=setTimeout(()=>{
                if(this.confirmPassword === this.formData.password){
                    this.startPasswordValidate = false;
                }else{
                    this.startPasswordValidate = true;
                }
            },600)
        },
        //点击注册按钮
        async clickRegisterBtnHandler(){
            if(!(/^1[3456789]\d{9}$/.test(this.formData.phoneNumber)&&/^\d{6}$/.test(this.formData.code)&&this.confirmPassword === this.formData.password)){
                return;
            }
            if(this.formData.password.trim().length<8){
                this.$message.warning('密码长度至少8位')
            }
            try{
                const {data} = await register(this.formData);
                if(data.code==200){
                    this.$router.push('/login');
                    this.$message.success("注册成功，请登录")
                }else{
                    this.$message.warning("注册失败，请稍后再试")
                }
            }catch(err){};
            
        },
    },
    beforeDestroy() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }
  }
  </script>
  
  <style lang="less" scoped>
  .container{
    //   width: calc(100vw);
    //height: calc(100vh);
      width: 1440px;
      height: 694px;
      .register{
          margin: 0 auto;
          width: 1200px;
          height: 694px;
          position: relative;

          .register-box{
            width: 400px;
            height: 556px;
            border-radius: 12px;
            background: #FFFFFF;
            position: absolute;
            top: 80px;
            right: 0px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .top{
                width: 400px;
                height: 56px;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.03);
                border-radius: 12px 12px 0px 0px;
                .title{
                    font-size: 16px;
                    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                    font-weight: bold;
                    color: #424242;
                    cursor: default;
                }
            }

            .input-box{
                display: flex;
                flex-direction: column;
                // padding: 0 30px 0;
                .input-phone-text-box{
                    margin-top: 30px;
                    width: 340px;
                    height: 46px;
                    position: relative;
                    .input-phone-text{
                        width: 340px;
                        height: 46px;
                        background: #FFFFFF;
                        border-radius: 4px;
                        border: 1px solid #EEEEEE;
                        padding-left: 16px;
                        &:focus{
                            outline: none;
                            border: 1px solid #F02233;
                        }
                        &::placeholder{
                            font-size: 14px;
                            font-family: MicrosoftYaHei;
                            color: #C3C3C3;
                        }
                    }
                    .input-phone-tips-text{
                        position: absolute;
                        left: 0px;
                        bottom: -16px;
                        width: 340px;
                        height: 12px;
                        font-family: MicrosoftYaHei;
                        font-size: 12px;
                        color: #F02233;
                        line-height: 12px;
                        text-align: left;
                        font-style: normal;
                    }
                }

                .sms-code-box{
                    margin-top: 30px;
                    display: flex;
                    justify-content: space-between;
                    cursor: default;
                    position: relative;
                    .sms-code{
                        width: 190px;
                        height: 46px;
                        background: #FFFFFF;
                        border-radius: 4px;
                        padding-left: 16px;
                        border: 1px solid #EEEEEE;
                        &::-webkit-inner-spin-button,
                        &::-webkit-outer-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }

                        &:focus{
                            outline: none;
                            border: 1px solid #F02233;
                         }
                        &::placeholder{
                            font-size: 14px;
                            font-family: MicrosoftYaHei;
                            color: #C3C3C3;
                        }
                    }
                    input[type='number'] {
                            appearance:textfield;
                            -moz-appearance: textfield;
                        }
                    .sms-code-btn{
                        width: 130px;
                        height: 46px;
                        background: #F02233;
                        border-radius: 4px;
                        line-height: 46px;
                        text-align: center;
                        span{
                            font-size: 14px;
                            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                            font-weight: bold;
                            color: #FFFFFF;
                        }
                    }
                    .sms-code-tips-text{
                        position: absolute;
                        left: 0px;
                        bottom: -16px;
                        width: 340px;
                        height: 12px;
                        font-family: MicrosoftYaHei;
                        font-size: 12px;
                        color: #F02233;
                        line-height: 12px;
                        text-align: left;
                        font-style: normal;
                    }

                }

                .password-box{
                    margin-top: 30px;
                    width: 340px;
                    height: 46px;
                    position: relative;
                    &>.input-text-pw{
                        width: 340px;
                        height: 46px;
                        background: #FFFFFF;
                        border-radius: 4px;
                        border: 1px solid #EEEEEE;
                        padding-left: 16px;
                        
                        &:focus{
                            outline: none;
                            border: 1px solid #F02233;
                        }
                        &::placeholder{
                            font-size: 14px;
                            font-family: MicrosoftYaHei;
                            color: #C3C3C3;
                        }
                    }

                    .show-or-hidden-password-btn{
                        width: 40px;
                        height: 46px;
                        border-radius: 0px 4px 4px 0px;
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .show-or-hidden-password-icon{
                            width: 16px;
                            height: 12px;
                        }
                    }
                    .password-tips-text{
                        position: absolute;
                        left: 0px;
                        bottom: -16px;
                        width: 340px;
                        height: 12px;
                        font-family: MicrosoftYaHei;
                        font-size: 12px;
                        color: #F02233;
                        line-height: 12px;
                        text-align: left;
                        font-style: normal;
                    }
                }

            }

            .register-btn{
                margin-top: 30px;
                width: 340px;
                height: 46px;
                background: #F02233;
                border-radius: 4px;
                line-height: 46px;
                text-align: center;
                cursor: default;
                span{
                    font-size: 16px;
                    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                    font-weight: bold;
                    color: #FFFFFF;
                }
            }

            .go-to-login{
                margin-top: 20px;
                display: flex;
                cursor: default;
                .text-1{
                    font-size: 16px;
                    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                    font-weight: bold;
                    color: #424242;
                }
                .text-2{
                    font-size: 16px;
                    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                    font-weight: bold;
                    color: #F02233;
                }
            }

            .user-agreement{
                margin-top: 30px;
                width: 340px;
                height: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: default;
                .icon-select{
                    width: 14px;
                    height: 14px;
                    margin-right: 5px;
                }
                .text-1{
                    font-size: 12px;
                    font-family: MicrosoftYaHei;
                    color: #949494;
                }
                .text-2{
                    font-size: 12px;
                    font-family: MicrosoftYaHei;
                    color: #7693B4;
                }
            }

          }
      }
  }
  </style>