<template>
  <div class="register">
    <Header/>
    <Content/>
  </div>
</template>

<script>
import Header from '../login/components/login-header.vue';
import Content from './components/register-content.vue';

export default {
    components:{
        Header,
        Content
    }
}
</script>

<style scoped>
.register{
    width: calc(100vw);
    min-width: 1440px;
    height: calc(100vh);
    min-height: 825px;
    background-image: url(https://hwj-games-market.oss-cn-shanghai.aliyuncs.com/background/register_bg_mini.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
</style>